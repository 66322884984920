<template>
  <div>
    <br class="d-none d-md-block" />

    <v-row>
      <v-col cols="12">
        <div class="table">
          <div class="table-header">
            <v-row class="d-none d-md-flex">
              <v-col cols="12" md="5" class="caption">{{
                $t('pdp.document')
              }}</v-col>

              <v-col cols="12" md="2" class="caption text-center">
                {{ $t('pdp.uploadDate') }}
              </v-col>

              <v-col cols="12" md="2" class="caption text-center">
                {{ $t('pdp.lastReviewDate') }}
              </v-col>

              <v-col cols="12" md="3" class="caption text-center">
                {{ $t('pdp.nextReviewDate') }}
              </v-col>
            </v-row>
          </div>

          <template v-if="pdpData && pdpData.isProcessCreated && !loading">
            <div
              v-if="pdpData.latestDocument !== null"
              class="overview-body table-body"
            >
              <br />
              <v-row>
                <v-col cols="12" md="5" class="py-0 py-md-3">
                  <strong class="d-inline d-md-none">
                    {{ $t('pdp.document') }}:
                  </strong>

                  <a
                    href="#"
                    @click.prevent="downloadFile(pdpData.latestDocument)"
                  >
                    {{ pdpData.latestDocument.fileName }}
                  </a>

                  <span
                    class="float-right expandable d-none d-md-block"
                    v-show="pdpData.revisions.length > 0"
                    @click="showRevisions = !showRevisions"
                  >
                    <span>{{
                      $tc('pdp.archivedFiles', pdpData.revisions.length, {
                        count: pdpData.revisions.length
                      })
                    }}</span>
                    <v-icon v-show="showRevisions">mdi-chevron-up</v-icon>
                    <v-icon v-show="!showRevisions">mdi-chevron-down</v-icon>
                  </span>
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                  class="py-0 py-md-3 text-left text-md-center"
                >
                  <strong class="d-inline d-md-none">
                    {{ $t('pdp.uploadDate') }}:
                  </strong>

                  {{ $d(Date.parse(pdpData.lastUpload)) }}
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                  class="py-0 py-md-3 text-left text-md-center"
                >
                  <template v-if="pdpData.lastReview">
                    <strong class="d-inline d-md-none">
                      {{ $t('pdp.lastReviewDate') }}:
                    </strong>

                    {{ $d(Date.parse(pdpData.lastReview)) }}
                  </template>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  class="py-0 py-md-3 text-left text-md-center"
                >
                  <template>
                    <strong class="d-inline d-md-none">
                      {{ $t('pdp.nextReviewDate') }}:
                    </strong>

                    <pdp-review-date
                      v-model="pdpData.nextReview"
                      :readOnly="readonly"
                      @exportDate="exportReviewDate"
                    ></pdp-review-date>
                  </template>
                </v-col>

                <v-col cols="12" class="py-0 pl-3 d-flex d-md-none mt-2">
                  <span
                    class="expandable"
                    v-show="pdpData.revisions.length > 0"
                    @click="showRevisions = !showRevisions"
                  >
                    <span>{{
                      $tc('pdp.archivedFiles', pdpData.revisions.length, {
                        count: pdpData.revisions.length
                      })
                    }}</span>
                    <v-icon v-show="showRevisions">mdi-chevron-up</v-icon>
                    <v-icon v-show="!showRevisions">mdi-chevron-down</v-icon>
                  </span>
                </v-col>
              </v-row>
            </div>
            <div v-else class="mt-2">
              <v-row>
                <v-col cols="12" md="5" class="caption text-uppercase muted">
                  <em>{{ $t('pdp.noDocumentsUploaded') }}</em>
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                  class="caption text-center d-none d-md-flex"
                >
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                  class="caption text-center d-none d-md-flex"
                >
                  <span v-if="pdpData.lastReview">
                    {{ $d(Date.parse(pdpData.lastReview)) }}
                  </span>
                </v-col>

                <v-col cols="12" md="3" class="caption text-md-center">
                  <pdp-review-date
                    v-model="pdpData.nextReview"
                    :readOnly="readonly"
                    @exportDate="exportReviewDate"
                  ></pdp-review-date>
                </v-col>
              </v-row>
            </div>

            <v-expand-transition>
              <div
                v-if="
                  showRevisions &&
                  pdpData.revisions &&
                  pdpData.revisions.length > 0
                "
                class="overview-body expanded-content pb-4 pb-md-0 mt-8 mt-md-4"
              >
                <div class="table-header mb-6 mb-md-0">
                  <v-row>
                    <v-col cols="12" md="6" class="caption">{{
                      $t('pdp.revisions')
                    }}</v-col>
                    <v-col
                      cols="12"
                      md="2"
                      class="caption text-center d-none d-md-block"
                    >
                      {{ $t('pdp.uploadDate') }}
                    </v-col>
                  </v-row>
                </div>

                <v-row
                  v-for="(revision, index) in pdpData.revisions"
                  :key="`pdp_revision_${index}`"
                  class="expanded-row mt-4 mt-md-2"
                >
                  <v-col cols="12" md="6" class="py-0 py-md-3">
                    <strong class="d-inline d-md-none">
                      {{ $t('pdp.document') }}:
                    </strong>

                    <a href="#" @click.prevent="downloadFile(revision)">
                      {{ revision.fileName }}
                    </a>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    class="py-0 py-md-3 text-left text-md-center"
                  >
                    <strong class="d-inline d-md-none">
                      {{ $t('pdp.uploadDate') }}:
                    </strong>

                    {{ $d(Date.parse(revision.uploadDateUtc)) }}
                  </v-col>

                  <v-row
                    v-if="index < pdpData.revisions.length - 1"
                    class="d-flex d-md-none py-0 my-0 mx-0"
                  >
                    <v-col class="px-2">
                      <hr class="ma-0" />
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </v-expand-transition>
          </template>

          <div
            v-else-if="pdpData && !pdpData.isProcessCreated && !loading"
            class="mt-4"
          >
            <v-row>
              <v-col cols="12">
                <v-alert text type="info">{{ $t('noDataAvailable') }}</v-alert>
              </v-col>
            </v-row>
          </div>

          <div v-else-if="loading" class="mt-4">
            <v-row>
              <v-col cols="12">
                <v-progress-linear indeterminate></v-progress-linear>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import pdpService from '@/services/PdpService.js';
import pdpReviewDate from './pdp-review-date.vue';

import { mapActions } from 'vuex';

export default {
  props: {
    pdpData: {
      type: Object,
      required: false,
      default: () => null
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    showRevisions: false,
    nextReviewDate: '',
    showReviewPicker: false
  }),

  methods: {
    ...mapActions({
      getCalendarData: 'pdp/exportReviewDate'
    }),

    async downloadFile(doc) {
      const data = await pdpService.downloadFile(doc.downloadUrl);
      const blob = new Blob([data], { type: doc.mimeType });
      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;
      anchor.download = doc.fileName;
      anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    async exportReviewDate() {
      const data = await this.getCalendarData(this.pdpData.processId);
      const blob = new Blob([data.calendarFileContent], {
        type: 'text/calendar'
      });
      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;
      anchor.download = 'Kalender.ics';
      anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    saveReviewDate() {
      this.$emit('reviewDateSelected', this.nextReviewDate);
    }
  },

  components: {
    pdpReviewDate
  }
};
</script>

<style lang="scss" scoped>
.expanded-content {
  background-color: var(--element-background-secondary);
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 1em;
  padding-top: 0;
  padding-left: 16px;
  padding-right: 16px;
}

.expandable:hover {
  cursor: pointer;
}

.theme--light a {
  color: rgb(34, 46, 62);
}
</style>
