<template>
  <div>
    <template v-if="readOnly">
      <template v-if="value">
        {{ $d(value) }}
      </template>
    </template>
    <template v-else>
      <v-menu
        v-if="!value"
        v-model="showReviewPicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-model="showReviewPicker" small v-bind="attrs" v-on="on">
            <v-icon small class="mr-2">mdi-calendar</v-icon>
            {{ $t('chooseDate') }}
          </v-btn>
        </template>
        <!-- <v-date-picker
          v-model="nextReviewDate"
          @input="showReviewPicker = false"
          no-title
          first-day-of-week="1"
        ></v-date-picker> -->

        <datetime-picker
          ref="dateTimePicker"
          :date="nextReviewDate"
          @selected="reviewDateSelected"
        ></datetime-picker>
      </v-menu>

      <span v-else>
        <span v-if="nextReviewDate">{{
          $d(Date.parse(nextReviewDate), 'datetime')
        }}</span>
        <v-menu
          v-model="showReviewPicker"
          :close-on-content-click="false"
          :nudge-left="100"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="showReviewPicker"
              x-small
              text
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <datetime-picker
            ref="dateTimePicker2"
            :date="nextReviewDate"
            @selected="reviewDateSelected"
          ></datetime-picker>
        </v-menu>
      </span>
    </template>

    <template v-if="value">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            x-small
            text
            fab
            class="px-0"
            v-on="on"
            @click="exportReviewDate"
          >
            <v-icon small>mdi-calendar-export</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('feedbacks.exportReviewDate') }}</span>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
import datetimePicker from '@/components/datetime-picker.vue';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    showReviewPicker: false,
    nextReviewDate: null,
    displayDate: null
  }),

  watch: {
    nextReviewDate() {
      this.displayDate = this.stringifyDate(this.nextReviewDate);
      this.$emit('input', this.nextReviewDate);
    },

    value() {
      this.nextReviewDate = this.value;

      if (this.nextReviewDate) {
        this.displayDate = this.stringifyDate(this.nextReviewDate);
      }
    }
  },

  mounted() {
    this.nextReviewDate = this.value;
  },

  methods: {
    exportReviewDate() {
      this.$emit('exportDate');
    },

    reviewDateSelected(selectedDate) {
      const isoReviewDate = selectedDate.toISOString();

      this.nextReviewDate = isoReviewDate;
      this.showReviewPicker = false;
    },

    stringifyDate(unformattedDate) {
      if (unformattedDate === null) {
        return '';
      }

      const dt = new Date(unformattedDate);
      const stringyfied = dt.toLocaleDateString('de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });

      return stringyfied;
    }
  },

  components: {
    datetimePicker
  }
};
</script>
