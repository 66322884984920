<template>
  <div>
    <v-chip label :color="color">
      <v-icon v-show="icon" small class="mr-1">{{ icon }}</v-icon>
      {{ label }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    feedback: {
      type: Object,
      required: true
    }
  },

  computed: {
    color() {
      if (this.feedback.isLocked) {
        return 'red';
      } else if (!this.feedback.submitted && !this.feedback.inProgress) {
        return 'orange';
      } else if (this.feedback.inProgress) {
        return 'blue';
      } else if (this.feedback.submitted && !this.feedback.approved) {
        return 'green';
      } else if (this.feedback.submitted && this.feedback.approved) {
        return 'green';
      }
      return '';
    },

    label() {
      if (this.feedback.isLocked) {
        return this.$t('feedbacks.notEditable');
      } else if (!this.feedback.submitted && !this.feedback.inProgress) {
        return this.$t('feedbacks.notSubmitted');
      } else if (this.feedback.inProgress) {
        return this.$t('feedbacks.inProgress');
      } else if (this.feedback.submitted && !this.feedback.approved) {
        return this.$t('feedbacks.submitted');
      } else if (this.feedback.submitted && this.feedback.approved) {
        return this.$t('feedbacks.approved');
      }
      return '';
    },

    icon() {
      if (this.feedback.isLocked) {
        return 'mdi-lock';
      } else if (!this.feedback.submitted && !this.feedback.inProgress) {
        return 'mdi-clipboard-outline';
      } else if (this.feedback.inProgress) {
        return 'mdi-pencil';
      } else if (this.feedback.submitted && !this.feedback.approved) {
        return 'mdi-file-move-outline';
      } else if (this.feedback.submitted && this.feedback.approved) {
        return 'mdi-check';
      }
      return '';
    }
  }
};
</script>
