<template>
  <v-row>
    <v-col cols="12" md="4" class="py-1 py-md-3">
      <strong>{{ feedbackTitle }}</strong>
    </v-col>

    <v-col cols="12" md="3" class="py-1 py-md-3">
      <status-label :feedback="item"></status-label>
    </v-col>

    <v-col cols="12" md="2" class="py-1 py-md-3">
      <v-btn
        small
        text
        :to="{ name: 'FeedbackForm', params: { id: item.id } }"
        :disabled="!item.canRead"
        class="px-2"
      >
        <v-icon>mdi-clipboard-text</v-icon>
      </v-btn>

      <v-btn
        small
        text
        :disabled="!item.canRead"
        :to="{ name: 'PrintFeedback', params: { id: item.id } }"
        target="_blank"
        class="px-2"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>

      <v-btn
        v-if="isPdcProcess"
        small
        text
        :disabled="!item.canRead || !item.submitted"
        class="px-2"
        target="_blank"
        @click="
          download(
            pdcProcessDownloadUrl,
            getPdcProcessDownloadFileName(),
            'text/html'
          )
        "
      >
        <v-icon>mdi-file-powerpoint</v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      md="3"
      class="d-flex justify-center justify-md-end mb-6 mb-md-0"
    >
      <v-btn
        v-if="item.canWrite && !item.submitted"
        color="secondary"
        :to="{ name: 'FeedbackForm', params: { id: item.id } }"
        :block="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon class="mr-2">mdi-pencil-outline</v-icon>
        {{ $t('feedbacks.goToForm') }}
      </v-btn>

      <v-btn
        v-if="item.canApprove && item.submitted && !item.canUpdate"
        color="secondary"
        :to="{ name: 'FeedbackForm', params: { id: item.id } }"
        :block="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon class="mr-2">mdi-check-circle</v-icon>
        {{ $t('feedbacks.goToApproval') }}
      </v-btn>

      <v-btn
        v-if="item.canUpdate && item.submitted"
        color="secondary"
        :to="{ name: 'FeedbackForm', params: { id: item.id } }"
        :block="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon class="mr-2">mdi-pencil-outline</v-icon>
        {{ $t('feedbacks.goToUpdate') }}
      </v-btn>

      <v-btn
        v-if="userCanVeto(item)"
        color="secondary"
        :to="{ name: 'FeedbackForm', params: { id: item.id } }"
        :block="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon class="mr-2">mdi-pencil-outline</v-icon>
        {{ $t('feedbacks.goToVeto') }}
      </v-btn>

      <v-btn
        v-if="item.canComment"
        color="secondary"
        :to="{ name: 'FeedbackForm', params: { id: item.id } }"
        :block="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon class="mr-2">mdi-pencil-outline</v-icon>
        {{ $t('feedbacks.goToComment') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import statusLabel from './feedback-status-label.vue';
import api from '@/helpers/api.js';
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    processType: {
      type: Number,
      required: true
    }
  },

  computed: {
    isPdcProcess() {
      return (
        this.processType === feedbackEnums.processTypes.PDC ||
        this.processType === feedbackEnums.processTypes.EDC
      );
    },

    pdcProcessDownloadUrl() {
      return `/download/pdc/${this.item.id}`;
    },

    feedbackTitle() {
      if (this.item.title?.length > 0 ?? false) {
        return this.localize(this.item.title);
      }
      return this.$t(`feedbacks.typeOptions.${this.item.feedbackType}`);
    }
  },

  methods: {
    async download(filePath, fileName, mimeType) {
      var file = await api.download(filePath);
      const blob = new Blob([file], {
        type: mimeType
      });

      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    getPdcProcessDownloadFileName() {
      if (this.processType === feedbackEnums.processTypes.EDC) {
        return 'EDC.html';
      } else {
        return 'PDC.html';
      }
    },

    userCanVeto(feedback) {
      if (!feedback.canVeto || !feedback.submitted) {
        return false;
      }

      // if the user can approve or update we already have a button
      // to get to the documentation. Thus we don't need an extra
      // veto button.
      if (feedback.canApprove || feedback.canUpdate) {
        return false;
      }

      return true;
    }
  },

  components: {
    statusLabel
  }
};
</script>
