<template>
  <div>
    <v-tabs v-model="activeTab" center-active>
      <v-tab>
        <v-icon>mdi-calendar</v-icon>
      </v-tab>

      <v-tab>
        <v-icon>mdi-clock</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-date-picker
          v-model="talkDate"
          @change="selectDate"
          :first-day-of-week="1"
        ></v-date-picker>
      </v-tab-item>
      <v-tab-item>
        <v-time-picker
          v-model="time"
          ref="timePicker"
          format="24hr"
          @change="setDateTime"
        ></v-time-picker>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    talkDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    time: null,
    activeTab: 0
  }),

  mounted() {
    if (this.date !== null && this.date !== '') {
      this.talkDate = this.date.substr(0, 10);
    }
  },

  methods: {
    reset() {
      this.activeTab = 0;

      if (this.date !== null && this.date !== '') {
        this.talkDate = this.date.substr(0, 10);
      } else {
        this.talkDate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }

      this.time = null;

      if (this.$refs.timePicker !== null) {
        this.$refs.timePicker.selecting = 1;
        this.$refs.timePicker.inputHour = null;
        this.$refs.timePicker.inputMinute = null;
      }
    },

    selectDate() {
      this.activeTab = 1;
    },

    setDateTime() {
      this.activeTab = 0;

      const dateParts = this.talkDate.split('-');
      const timeParts = this.time.split(':');

      const date = new Date(
        dateParts[0],
        dateParts[1] - 1,
        dateParts[2],
        timeParts[0],
        timeParts[1]
      );

      this.$emit('selected', date);
    }
  }
};
</script>

<style></style>
